<template>
  <Authenticated>
    <div class="actions">
      <h2>Upcoming Orders</h2>
    </div>

    <div class="orders">
      <div class="filter-nav">
        <h2 v-if="view">Product View</h2>
        <h2 v-else>Customer View</h2>
        <div class="filter-options">
          <button @click="view = !view">Customer/Products</button>
        </div>
      </div>
      <ul class="btn-layout">
        <li @click="changeWeek('previous')"><font-awesome-icon icon="fa-arrow-left"></font-awesome-icon></li>
        <template v-for="(date, idx) in week" :key="date + idx">
          <li @click="setDay(date)" :style="{ backgroundColor: this.orderDate === date ? 'var(--action-colour)' : '' }" class="day" :id="date">
            <font-awesome-icon
              icon="fa-calendar-days"
              class="today"
              v-if="date === `${today.getFullYear()}-${(today.getMonth() + 1 < 10 ? '0' : '') + (today.getMonth() + 1)}-${(today.getDate() < 10 ? '0' : '') + today.getDate()}`"></font-awesome-icon>
            {{ date }}
          </li></template
        >
        <li @click="changeWeek('next')"><font-awesome-icon icon="fa-arrow-right"></font-awesome-icon></li>
      </ul>
      <ul class="btn-layout">
        <li
          v-for="(site, idx) in $store.getters.siteSettings.pickupSites"
          :key="idx + site"
          :style="{ backgroundColor: this.location === site ? 'var(--action-colour)' : '' }"
          @click="this.location = site">
          {{ site }}
        </li>
      </ul>
      <Datepicker class="date-picker" v-model="this.orderDate" @update:model-value="handleDate" :format="format" :enableTimePicker="false" auto-apply></Datepicker>
      <template v-if="orders.length > 0">
        <table class="order-table-products" v-if="view">
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Modifier</th>
            <th>Quantity</th>
          </tr>
          <tr v-for="order in orderTotals" v-bind:key="order">
            <td>{{ order.name }}</td>
            <td>{{ order.category }}</td>
            <td>{{ order.modifier }}</td>
            <td>{{ order.quantity }}</td>
          </tr>
        </table>
        <ul class="order-table-customer" v-else>
          <li v-for="order in this.orders" v-bind:key="order">
            <p>{{ order.value.name }}</p>
            <p>{{ order.value.cell }}</p>
            <p>{{ order.value.notes }}</p>

            <table class="customer-products-table">
              <tr>
                <th>Name</th>
                <th>Quantity</th>
                <th>Modifiers</th>
              </tr>
              <tr v-for="prod in order.value.products" v-bind:key="prod">
                <td>{{ prod.name }}</td>
                <td>{{ prod.quantity }}</td>
                <td>
                  <p v-for="mod in prod.modifier_list" v-bind:key="mod">{{ mod.name }}</p>
                </td>
              </tr>
            </table>
          </li>
        </ul></template
      >
      <h2 v-else>No Orders Found on this date at this location</h2>
    </div>
  </Authenticated>
</template>

<script>
/** DATE IS EXPECTED AS YYYY/MM/DD */
import Authenticated from "@/components/_layouts/Authenticated";
import { api } from "@/helpers/api";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import createdMixin from "@/components/_mixins/createdMixin";

export default {
  mixins: [createdMixin],
  components: { Authenticated, Datepicker },
  data() {
    return {
      orders: [],
      orderDate: null,
      view: true,
      location: "city",
      week: null,
      today: null,
    };
  },
  async created() {
    this.orderDate = new Date();
    this.today = new Date();
  },
  setup() {
    const format = (orderDate) => {
      return `Selected date is ${orderDate.toISOString().slice(0, 10)}`;
    };
    return {
      format,
    };
  },
  methods: {
    handleDate() {
      this.orderDate = this.formatDate(this.orderDate);
      if (!this.week.includes(this.orderDate)) {
        this.week = this.getWeekDates(this.orderDate);
      }
      this.apiCall();
    },
    formatDate(date) {
      return `${date.toISOString().slice(0,10)}`;	
    },
    changeWeek(direction) {
      if (!this.week) {
        this.week = this.dateWeek;
      }
      this.week = this.week.map((date) => {
        let newDate = new Date(date);
        newDate.setDate(direction == "next" ? newDate.getDate() + 7 : newDate.getDate() - 7);
        return this.formatDate(newDate);
      });
    },
    getWeekDates(inputDate) {
      const date = new Date(inputDate);
      const day = date.getDay();
      const diff = date.getDate() - day + (day == 0 ? -6 : 1); // Adjust for Sunday
      const monday = new Date(date.setDate(diff));
	
      const weekDates = [];
      for (let i = 0; i < 7; i++) {
        const currentDate = new Date(monday);
        currentDate.setDate(monday.getDate() + i);
        weekDates.push(currentDate.toISOString().slice(0, 10));
      }

      return weekDates;
    },

    //sets the selected date to the date clicked on
    // and highlights the date
    setDay(date) {
      document.querySelectorAll(".day").forEach((element) => {
        element.style.removeProperty("background-color");
      });
      document.getElementById(date).style.backgroundColor = "var(--action-colour)";
      this.orderDate = date;
      this.apiCall();
    },
    locationSelect() {
      this.apiCall();
    },
    async apiCall(od = this.orderDate) {
      const companyString = localStorage.getItem("company");
      const siteString = window.localStorage.getItem("site");
      let allOrders = await api.post("/api/get_future_orders/" + companyString + "/" + siteString, { key: '["' + od + '",' + '"' + this.location + '"]' });
      this.orders = allOrders.rows;
    },
    async getOrders(od = this.orderDate) {
      const companyString = localStorage.getItem("company");
      const siteString = window.localStorage.getItem("site");
      let allOrders = await api.post("/api/get_future_orders/" + companyString + "/" + siteString, { key: '["' + od + '",' + '"' + this.location + '"]' });
      return allOrders.rows;
    },
  },
  computed: {
    dateWeek() {
      let dateArray = [];
      let date = new Date(this.today);
      let day = date.getDay();
      let diff = date.getDate() - day + (day == 0 ? -6 : 1);
      let monday = new Date(date.setDate(diff));
      for (let i = 0; i < 7; i++) {
        dateArray.push(monday.toISOString().slice(0,10));
        monday.setDate(monday.getDate() + 1);
      }

      return dateArray;
    },
    orderTotals() {
      let productHash = {};

      this.orders.forEach((order) => {
        order.value.products.forEach((element) => {
          if (productHash[element.category + element.hash]) {
            let newQuantity = productHash[element.category + element.hash].quantity + element.quantity;
            productHash[element.category + element.hash].quantity = newQuantity;
          } else {
            let modString = "";
            if ("modifier_list" in element && element["modifier_list"].length !== 0) {
              element.modifier_list.forEach((mod) => {
                modString.length > 0 ? (modString += " - " + mod.name) : (modString += mod.name);
              });
            }

            productHash[element.category + element.hash] = { category: element.category, quantity: element.quantity, name: element.name, modifier: modString };
          }
        });
      });
      const sorted = Object.keys(productHash)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = productHash[key];

          return accumulator;
        }, {});

      return sorted;
    },
  },
  mounted() {
    this.$store.commit("currPage", "orders");

    //set new variable as dateweek is readonly
    this.week = this.dateWeek;
    //sets order date to todays date
    //this.orderDate = `${this.today.getFullYear()}-${(this.today.getMonth() + 1 < 10 ? "0" : "") + (this.today.getMonth() + 1)}-${(this.today.getDate() < 10 ? "0" : "") + this.today.getDate()}`;
    this.orderDate = `${this.today.toISOString().slice(0,10)}`;
    //highlights todays date on inital load
    this.apiCall();
  },
  watch: {
    location() {
      this.apiCall();
    },
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";

.orders {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 1rem;

  .filter-nav {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }

  .filter-options {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;

    button {
      margin-left: 0rem;
    }
    select {
      width: 5rem;
      height: 3rem;
    }
  }

  .btn-layout {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    li {
      float: left;
      display: block;
      color: var(--text-colour);
      text-align: center;
      padding: 8px;
      text-decoration: none;
      border: 1px solid var(--action-colour);
      margin: 0.5rem;
      position: relative;
      border-radius: 0.5rem;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        background-color: var(--secondary-colour);
      }
    }
    .today {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      color: var(--action-colour);
      background-color: var(--primary-colour);
      border: 2px solid var(--primary-colour);
      border-radius: 0.25rem;
    }
  }

  .date-picker {
    width: 90%;
  }
  .order-table-products {
    font-family: arial, sans-serif;
    border-collapse: seperate;
    border-spacing: 0;
    width: 90%;
    margin-bottom: 2rem;
    border-radius: 0.25rem;
    border: 1px solid #dddddd;
    td,
    th {
      border-bottom: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    tr:nth-child(even) {
      background-color: var(--secondary-colour);
    }
  }
  .order-table-customer {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    padding-inline-start: 0px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    background-color: var(--secondary-colour);
    border-radius: 0.25rem;
    td,
    th {
      border: 0px solid #dddddd;
      text-align: left;
      padding: 8px;
    }

    li {
      margin: 1rem;
      max-width: 22rem;
      box-shadow: 2px 2px 2px var(--action-colour);
      border: 1px solid #dddddd;
      border-radius: 0.25rem;
      padding: 0.5rem;
      background-color: var(--primary-colour);
      p {
        word-wrap: break-all;
      }
      table {
        max-width: 22rem;
        border: 1px solid var(--action-colour);
        border-spacing: 0;
        border-collapse: separate;
        border-radius: 0.25rem;
        td:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (orientation: portrait) {
  .orders {
    .date-picker {
      width: 100%;
    }
    .order-table-customer {
      width: 100%;
    }
  }
}
</style>
